<template>
  <div class="page">
    <div v-if="getModuleConfig('external_member_customer_bind.overwrite_check.step_section.enable_step_section')" class="p-3">
      <SharedStep :steps="getModuleConfig('thanks_page.step_section.steps')" />
    </div>

    <div v-if="getModuleConfig('external_member_customer_bind.overwrite_check.form_title')" class="page__title">
      {{ getModuleConfig("external_member_customer_bind.overwrite_check.form_title") }}
    </div>
    <div v-if="getModuleConfig('external_member_customer_bind.overwrite_check.form_desc')" class="page__desc">
      {{ getModuleConfig("external_member_customer_bind.overwrite_check.form_desc") }}
    </div>

    <div class="page_content" v-if="contentData" v-html="contentData"></div>

    <img
      v-for="image in contentImages"
      :src="image.image_url"
      :key="image.order"
      class="w-100"
    />

    <div class="page__button s-space-y-4">
        <MemberButton
          v-if="getModuleConfig('external_member_customer_bind.overwrite_check.confirm_text')"
          :button-text="getModuleConfig('external_member_customer_bind.overwrite_check.confirm_text')"
          :disableDefaultClick="true"
          @custom-click="overwriteConfirm"
        ></MemberButton>
        <MemberButton
          v-if="getModuleConfig('external_member_customer_bind.overwrite_check.cancel_text')"
          :button-text="getModuleConfig('external_member_customer_bind.overwrite_check.cancel_text')"
          :disableDefaultClick="true"
          :button-style="`s-btn-outline-primary`"
          @custom-click="cancel"
        ></MemberButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import registerMixin from "@/mixins/liff/register";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import SharedStep from "@/components/Page/Liff/Shared/Steps";
import registerApi from "@/apis/liff/v2/register";

export default {
  mixins: [registerMixin],
  components: {
    SharedStep,
    MemberButton
  },
  computed: {
    ...mapGetters({
      hasIntended: "route/hasIntended",
    }),
    contentImages() {
      let contentImages = this.getModuleConfig("external_member_customer_bind.overwrite_check.content_images") || []
      contentImages.sort((a, b) => a.order - b.order)
      return contentImages
    },
    contentData() {
      return this.getModuleConfig("external_member_customer_bind.overwrite_check.content") ?? false;
    },
  },
  async created() {
  },
  beforeMount() {
  },
  methods: {
    ...mapActions({
      intended: "route/intended",
    }),
    redirectToIntended() {
      this.intended();
    },
    async cancel() {
      await this.submit(false)
    },
    async submit($overwrite)
    {
      try {
        let response = await registerApi.overwriteBinding({
          is_overwrite: $overwrite,
        })

        let next = response.data.next ?? "success_page"

        if (next === 'success_page') {
          this.$router.push({
            name: "LiffRegisterWelcome",
            params: {
              orgCode: this.$route.params.orgCode,
            },
          });
          return;
        }

        await this.goNext(next);
      } catch (error) {
        console.error(error)
        this.$swal('錯誤', '重新綁定失敗', 'error');
        return false;
      }
    },
    async overwriteConfirm() {
      this.$swal.fire({
        title: '確認是否覆寫',
        text: '確認覆寫後，將會覆寫原有的綁定資料，舊帳號將無法繼續使用此綁定用戶。',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確認',
        cancelButtonText: '取消',
      }).then((result) => {
        if (result.value) {
          this.submit(true)
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.list {
  max-width: 425px;
  margin: auto;
}

.item {
  display: flex;
  margin-bottom: 16px;

  &__text {
    color: var(--liff-primary_text_color);
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 8px;
  }
}
</style>
